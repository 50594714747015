<template>
  <KPage>
    <!--  -->
    <FilterBar />

    <!--  -->
    <v-card outlined>
      <v-row class="ma-0">
        <v-col cols="12" xs="12" class="pa-0">
          <v-list class="pa-0 bordered">
            <v-list-item
              v-for="(item, key) in products"
              :key="key"
              @click.stop="() => edit(item)"
            >
              <v-list-item-avatar>
                <v-icon>mdi-zip-box</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
                <v-list-item-subtitle>
                  <KChip>
                    Unidade de coleta:
                    {{ item.supply_unit && item.supply_unit.name }}
                  </KChip>

                  <KChip
                    >Fator de conversão:
                    {{ item.conversion_coefficient }}</KChip
                  >
                  <!-- {{ item.description }} -->
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon @click.stop.prevent="() => remove(item)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <!-- </v-card-text> -->
          </v-list>
        </v-col>
      </v-row>
    </v-card>

    <!-- Caixa de Diálogo -->
    <DialogSave ref="dialog_save" />
    <DialogDelete ref="dialog_delete" />

    <!-- Botão FAB -->
    <FAB :visible="!loading" @click="add"></FAB>
    <!--  -->
  </KPage>
</template>

<script>
import KPage from "@/components/KPage";
import KForm from "@/components/KForm";
import KAvatar from "@/components/KAvatar";
import KChip from "@/components/KChip";
import FAB from "@/components/FAB";

import FilterBar from "./FilterBar";

import DialogSave from "./DialogSave";
import DialogDelete from "./DialogDelete";

export default {
  components: {
    KPage,
    KForm,
    KAvatar,
    KChip,
    FAB,

    FilterBar,

    DialogSave,
    DialogDelete
  },

  data() {
    return {
      //
      loading: false,

      //
      dialog: {
        visible: true
      }
    };
  },

  computed: {
    /**
     * Alimenta os partidos de forma reativa
     */
    products() {
      return this.$store.getters["products/all"];
    }
  },

  //
  methods: {
    /**
     * Abre o dialog como cadastro
     */
    add() {
      this.$refs["dialog_save"].openToAdd();
    },

    /**
     * Abre o dialog como edição
     */
    edit(data) {
      this.$refs["dialog_save"].openToEdit(data);
    },

    /**
     * Abre o dialog de exclusão
     */
    remove(data) {
      this.$refs["dialog_delete"].openToRemove(data);
    },

    /**
     * Carrega os partidos caso ainda não estejam
     */
    async load() {
      try {
        this.loading = true;
        this.$loading.show();
        await Promise.all([
          this.$store.dispatch("measurement_units/all"),
          this.$store.dispatch("products/all")
        ]);
        this.loading = false;
        this.$loading.hide();
      } catch (error) {
        this.$message.serverError(error);
        this.loading = false;
        this.$loading.hide();
      }
    }
  },

  mounted() {
    this.load();
  }
};
</script>

