<template>
  <KDialog
    :loading="loading"
    :title="title"
    :visible="visible"
    showClose
    :actions="actions"
    @click:add="save"
    @click:edit="save"
    @click:close="close"
  >
    <k-form ref="form">
      <v-row class="ma-0">
        <!-- Nome da Configuração -->
        <v-col cols="12">
          <v-text-field
            dense
            outlined
            :rules="[$validation.isRequired]"
            label="Nome"
            :error-messages="errors.name"
            v-model="data.name"
            persistent-hint
            required
          ></v-text-field>
        </v-col>

        <!-- Unidade de Medida de Abastecimento -->
        <v-col cols="12">
          <v-select
            :items="measurement_units"
            item-text="name"
            item-value="id"
            dense
            outlined
            :rules="[$validation.isRequired]"
            label="Unidade de Medida de Abastecimento"
            :error-messages="errors.supply_unit_id"
            v-model="data.supply_unit_id"
            persistent-hint
            required
          ></v-select>
        </v-col>

        <!-- Coeficiente de Conversão pra m³ -->
        <v-col cols="12">
          <KInputMoney
            dense
            outlined
            :rules="[$validation.isRequired]"
            label="Coeficiente de Conversão para m³"
            :error-messages="errors.conversion_coefficient"
            v-model="data.conversion_coefficient"
            persistent-hint
            required
          />
        </v-col>
      </v-row>
    </k-form>
  </KDialog>
</template>

<script>
import KForm from "@/components/KForm";
import KDialog from "@/components/KDialog";
import KInputMoney from "@/components/KInput/Money";

export default {
  components: {
    KForm,
    KDialog,
    KInputMoney
  },

  data() {
    return {
      // Define se está visível
      visible: false,
      // Armazena os dados que virão via função para preencher
      data: {},

      isEditing: false,
      title: null,

      loading: false,

      //
      errors: {
        name: null,
        slug: null,
        icon: null
      }
    };
  },

  computed: {
    actions() {
      if (this.isEditing) {
        return [
          {
            eventName: "click:edit",
            label: "Salvar Alterações"
          }
        ];
      }

      return [{ eventName: "click:add", label: "Cadastrar" }];
    },

    measurement_units() {
      return this.$store.getters["measurement_units/all"];
    }
  },

  methods: {
    /*******************************************************
     *
     *  FUNÇÕES PARA SALVAR E EDITAR OS DADOS NO STORE
     *
     *******************************************************/

    /**
     * Salva os dados no Store
     */
    async save() {
      try {
        //
        this.errors = {};
        // Se as demais validações passaram
        if (this.$refs["form"].validate()) {
          this.loading = true;
          //
          await this.$store.dispatch("products/save", {
            payload: this.data,
            editing: this.isEditing
          });

          this.loading = false;
          this.$message.success("Registro salvo com sucesso");
          this.close();
        } else {
          this.$message.error("Verifique os campos marcados em vermelho");

          this.loading = false;
        }
      } catch (error) {
        this.errors = this.$message.serverError(error);
        this.loading = false;
      }
    },

    /*******************************************************
     *
     *  FUNÇÕES BÁSICAS DE QUALQUER DIALOG
     *
     *******************************************************/

    /**
     * Abre o dialog preparado para cadastrar um novo dado
     */
    openToAdd() {
      // Altera o título
      this.title = "Novo Produto";

      // Limpa os dados
      this.clear();

      // Define se é uma edição
      this.isEditing = false;

      // Abre por último, depois que está tudo preenchido
      this.open();
    },

    /**
     * Abre o dialog preparado para alteerar os dados
     */
    openToEdit({ id, name, conversion_coefficient, supply_unit_id }) {
      // Altera o título
      this.title = `Editar "${name}"`;

      // Mescla os dados recebidos para não alterar o que está para trás
      this.data = {
        id,
        name,
        conversion_coefficient,
        supply_unit_id
      };

      // Define se é uma edição
      this.isEditing = true;

      // Abre por último, depois que está tudo preenchido
      this.open();
    },

    /**
     * Abre o dialog
     */
    open() {
      this.visible = true;
    },

    /**
     * Fecha o dialog
     */
    close() {
      this.$refs.form.resetValidation();
      this.visible = false;
      this.clear();
    },

    /**
     * Limpa os dados do dialog
     */
    clear() {
      this.data = {};
    }
  }
};
</script>

<style>
</style>
